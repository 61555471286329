import {Injectable} from "@angular/core";
import {CustomerDetails} from "src/app/speed-pay/models/customer-details";

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private sessionInfo: CustomerDetails = null;

    constructor() {
        // TODO should be removed when we're ready for prod
        console.log('session info ==> ', this.sessionInfo);
    }

    public getAciAccessToken(): string {
        return this.sessionInfo.tokens.aciToken;
    }

    public getAccountNumber(): string {
        return this.sessionInfo.customerInfo.accountNumber;
    }

    public getPaymentMethod(): 'Card' | 'ACH' | 'GooglePay' | 'ApplePay' {
        return this.sessionInfo.paymentInfo.paymentMethod;
    }

    public isGooglePay(): boolean {
        return this.getPaymentMethod() === 'GooglePay';
    }

    public isApplePay(): boolean {
        return this.getPaymentMethod() === 'ApplePay';
    }

    public getBilledId(): string {
        return this.sessionInfo.customerInfo.billerId;
    }

    public getSessionInfoObject(): CustomerDetails {
        this.sessionInfo = this.sessionInfo ?? JSON.parse(sessionStorage.getItem('sessionInfo'));
        return this.sessionInfo;
    }
}