import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  EdsFormHintsComponent,
  EdsIconButtonComponent,
  EdsIconMeta,
  EdsSvgComponent
} from '@electronds/ng-components-piedmont';
import {CommonModule, CurrencyPipe} from "@angular/common";

@Component({
  selector: 'cma-complex-card',
  standalone: true,
  imports: [CommonModule, EdsFormHintsComponent, EdsSvgComponent, EdsIconButtonComponent, CurrencyPipe],
  templateUrl: './complex-card.component.html',
})
export class ComplexCardComponent implements OnInit {
  @Input()
  public btnIconName = '';

  @Input()
  public cardTitle = '';

  @Input()
  public extraBtnCssClasses = '';

  @Input()
  public extraContainerCssClasses = '';

  @Input()
  public extraHeaderCssClasses = '';

  @Input()
  public extraTitleCssClasses = '';

  @Input()
  public rightHeaderAmount = '';

  @Output()
  public rightHeaderBtnCallBack: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public rightHeaderBtnText = '';

  public imageData!: EdsIconMeta;

  public emitBtnClicked(): void {
    this.rightHeaderBtnCallBack.emit();
  }

  public ngOnInit(): void {
    this.imageData = {
      src: this.btnIconName,
      iconClass: this.extraBtnCssClasses,
      altText: 'More Details',
    };
  }
}

