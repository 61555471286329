<body [ngClass]="{'overflow-hidden touch-none': isOpen}">

<!-- Overlay -->
<div class="fixed inset-0 z-modal {{overlayOpenCloseClasses}}">

  <!-- Overlay Background -->
  <div class="absolute inset-0 bg-gray-darker transition-opacity !bg-opacity-90 {{overlayBackgroundOpenCloseClasses}}"
       aria-hidden="true"></div>

  <!-- Modal -->
  <div class="relative justify-center text-center items-center">
    <div class="fixed bottom-0 w-full px-12 py-40 max-h-full overflow-y-auto rounded-t-lg shadow bg-white transition-all {{modalOpenCloseClasses}}"
         role="dialog"
         aria-modal="true"
         aria-labelledby="modal-title"
         aria-describedby="modal-description">

      <button *ngIf="allowClose"
              class="absolute p-16 md:p-8 top-0 md:top-16 right-0 md:right-16 transform hover:scale-110 transition-transform ease-in-out duration-300"
              type="button"
              aria-label="Close Dialog"
              (click)="handleCloseBtnClicked()">
        <svg class="fill-current text-teal-darker icon-16 md:icon-20"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24"
             aria-hidden="true">
          <path
            d="M2.782.477,12,9.7,21.218.477a1.63,1.63,0,0,1,2.3,2.305L14.305,12l9.218,9.218a1.63,1.63,0,0,1-2.3,2.3L12,14.305,2.782,23.523a1.63,1.63,0,0,1-2.305-2.3L9.7,12,.477,2.782A1.63,1.63,0,0,1,2.782.477Z" />
        </svg>
      </button>

      <div class="flex flex-col h-full {{extraTwClasses}}"
           role="document">
        <div class="rich-text">
          <img *ngIf="iconSrc"
               class="mx-auto icon-56 sm:icon-64 text-blue"
               [src]="iconSrc"
               alt="img" />
          <h2 class="text-2xl text-gray-darker my-20 {{titleTwClasses}}"
              id="modal-title">
            {{ title }}
          </h2>
          <h4 *ngIf="subTitle !== ''"
              class="text-1xl text-gray-darker mt-20 {{subTitleTwClasses}}"
              id="modal-sub-title">
            {{ subTitle }}
          </h4>
          <p *ngIf="content"
             class="p-12"
             id="modal-description">
            {{ content }}
          </p>
          <ng-container *ngIf="contentTemplate"
                        [ngTemplateOutlet]="contentTemplate">
          </ng-container>
        </div>

        <div *ngIf="primaryBtnText !== '' || secondaryBtnText !== ''"
             class="flex justify-center space-x-12 mt-24">
          <eds-button *ngIf="secondaryBtnText !== ''"
                      type="secondary"
                      extraTwClasses="{{secondaryBtnTwClasses}}"
                      [text]="secondaryBtnText"
                      (click)="handleSecondaryBtnClicked()">
          </eds-button>
          <!-- set focus on open -->
          <eds-button #primaryButton
                      *ngIf="primaryBtnText !== ''"
                      type="primary"
                      [text]="primaryBtnText"
                      extraTwClasses="{{primaryBtnTwClasses}}"
                      (click)="handlePrimaryBtnClicked()"
                      [isLoading]="primaryBtnIsLoading"
                      [loadingIcon]="primaryBtnLoadingIcon">
          </eds-button>
        </div>
      </div>

    </div>
  </div>
</div>