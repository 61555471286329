import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {EdsButtonComponent, EdsModalComponent} from "@electronds/ng-components-piedmont";

/**
 * Standard Modal
 *
 * @export
 * @class ModalComponent
 * @typedef {CmaModalComponent}
 */
@Component({
    selector: 'cma-modal',
    templateUrl: './modal.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgTemplateOutlet,
        EdsButtonComponent,
    ],
})
export class CmaModalComponent extends EdsModalComponent {
}
