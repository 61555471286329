import {Injectable} from "@angular/core";
import {SdkInitOptions} from "../models/sdk-init-options";
import {ISdkInitResponse} from "../models/sdk-init-response";
import {AciPaymentDetails} from "../models/aci-payment-details";
import {SessionStorageService} from "src/app/core/services/session-storage.service";

@Injectable({
    providedIn: 'root'
})
export class SpeedPayService {
    constructor(private _sessionStorageService: SessionStorageService) {}

    public async initSpeedPayScript(aci: any) {
        const options: SdkInitOptions = {
            accessToken: this._sessionStorageService.getAciAccessToken(),
            billedAccountId: this._sessionStorageService.getAccountNumber(),
            paymentMethod: this._sessionStorageService.getPaymentMethod(),
            userInterface: {
                iframe: {
                    width: '100%'
                },
                button: {
                    width: '100%',
                    color: 'black',
                }
            }
        };
        return await aci.speedpay.fundingAccountGateway
            .init(options, this.onCreateToken)
            .then((handler: ISdkInitResponse) => {
                if (handler) {
                    handler.paymentSheet.displayName = 'Duke Energy';
                    handler.paymentSheet.merchantIdentifier = 'speedpay-duke';
                    handler.paymentSheet.onShow = (): void => {
                        const paymentDetails: AciPaymentDetails = {
                            total: {
                                label: 'Grand Total',
                                amount: {currency: 'USD', value: 10}
                            },
                            displayItems: [
                                {
                                    label: 'Principal Amount',
                                    amount: {currency: 'USD', value: 9.00}
                                },
                                {
                                    label: 'Fees',
                                    amount: {currency: 'USD', value: 1.00}
                                },
                            ]
                        };
                        handler.paymentSheet.setPaymentDetails(paymentDetails);
                    };
                }
            });
    }

    private onCreateToken = (event: any): void => {
        console.log('Success!');

        if (event.id) {
            console.log('event.id ==> Success!', event.id);
        }
    };

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     * @returns string
     */
    private getMobileOperatingSystem() {
        const userAgent = navigator.userAgent;// || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent)) { // && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    private onSuccess(result: any): void { //IFundingAccount
        console.log('onSuccess', result);
    }

    private onValidation(validationResult: any): void { // IValidationResult
        console.log('validationResult', validationResult);
    }
}