import {Inject, Injectable, Renderer2} from '@angular/core';
import {ExternalScriptModel} from "../models/external-script.model";
import {Observable, Observer} from "rxjs";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    private loadedScripts: ExternalScriptModel[] = [];

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public loadScript(scriptToLoad: ExternalScriptModel, renderer: Renderer2): Observable<ExternalScriptModel> {
        return new Observable<ExternalScriptModel>((observer: Observer<ExternalScriptModel>) => {
            const loadedScript = this.loadedScripts.find(s => s.name == scriptToLoad.name);

            // Complete if already loaded
            if (loadedScript && loadedScript.loaded) {
                // resolve(loadedScript);
                observer.next(loadedScript);
                observer.complete();
            } else {
                // Load the scriptToLoad script
                let scriptElement = renderer.createElement('script');
                scriptElement.async = false;
                scriptElement.src = scriptToLoad.src;
                scriptElement.type = 'text/javascript';
                scriptElement.id = scriptToLoad.name;
                scriptElement.setAttribute('data-aci-speedpay', '');

                const body = this.document.body || this.document.getElementsByTagName('body')[0];
                renderer.appendChild(body, scriptElement);

                scriptElement.onload = () => {
                    // IE
                    // if (scriptElement.onreadystatechange) {
                    //     if (scriptElement.readyState === 'loaded' || scriptElement.readyState === 'complete') {
                    //
                    //     }
                    // }

                    // Add the scriptToLoad to the loadedScripts array
                    this.loadedScripts.push(scriptToLoad);

                    scriptToLoad.loaded = true;

                    observer.next(scriptToLoad);
                    observer.complete();
                };

                scriptElement.onerror = (_error: Event) => {
                    observer.error('Couldn\'t load script ' + scriptToLoad.src);
                };
            }
        });
    }
}
