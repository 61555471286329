<div class="rounded-md border border-solid border-gray bg-white {{ extraContainerCssClasses }}">
    <div class="flex items-center rounded-t-md bg-gray-lighter p-12 {{ extraHeaderCssClasses }}">
        <span class="{{ extraTitleCssClasses }}">
            {{ cardTitle }}
        </span>

        <ng-content select=".complexCardHeaderContent"></ng-content>

        <div class="flex items-center justify-end">
            <ng-container *ngIf="rightHeaderAmount">
                <span>{{ rightHeaderAmount | currency }}</span>
            </ng-container>

            <!-- more info button -->
            <ng-container *ngIf="btnIconName">
                <eds-icon-button (buttonClick)="emitBtnClicked()"
                                 [extraTwClasses]="extraBtnCssClasses"
                                 [iconPlacement]="'left'"
                                 [imageData]="imageData"
                                 [isDisabled]="false"
                                 [isLoading]="false"
                                 [isReversed]="false"
                                 [size]="'xs'"
                                 [text]="rightHeaderBtnText"
                                 [type]="'flat'">
                </eds-icon-button>
            </ng-container>
        </div>

    </div>

    <div class="block">
        <ng-content select=".complexCardContent"></ng-content>
    </div>
</div>
