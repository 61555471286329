import {Injectable} from '@angular/core';
import {EnvironmentConfig} from "src/app/config/models/environment-config.model";
import {environmentsConfig} from "src/app/config/envs-config/config";

@Injectable({providedIn: 'root'})
export class AppConfigService {
    private _config: EnvironmentConfig = environmentsConfig;

    constructor() {}

    public getSpeedPayScriptEndpoint(): string {
        return this._config['speedPayEndpoint'];
    }
}
