<div class="container">
    <div class="mx-24">
        <div class="text-md text-green-dark flex flex-col items-center my-12">
            <eds-svg [svgSizeClass]="'icon-24'"
                     [svgPath]="'/assets/icons/check.svg'">
            </eds-svg>
            <p class="mt-8">Thanks for your payment.</p>
        </div>

        <eds-inline-banner
                [extraTwClasses]="'my-24'"
                [message]="'Your account balance will update when the payment has processed.'"
                [status]="'info'">
        </eds-inline-banner>

        <div class="text-left p-12 border border-gray rounded-md">
            <h2 class="mt-8 font-bold">Confirmation</h2>
            <p>#{{ paymentResponse.confirmationNumber }}</p>

            <p
                    [ngClass]="paymentResponse.cpiMessage.messagePriority === 'HIGH' ? 'text-red-dark' : 'text-grey-dark'">
                {{ paymentResponse.cpiMessage.messageBody }}
            </p>

            <h2 class="mt-12 font-bold">Account Info</h2>
            <p>{{ paymentResponse.customerInfo.customerName.firstName }} {{ paymentResponse.customerInfo.customerName.lastName }}</p>
            <p>Account #{{ paymentResponse.customerInfo.accountNumber }}</p>
            <div>
                <p>
                    {{ paymentResponse.customerInfo.address.houseNumber }}
                    {{ paymentResponse.customerInfo.address.address1 }}
                    {{ paymentResponse.customerInfo.address.address2 }}</p>
                <p>{{ paymentResponse.customerInfo.address.city }}
                    , {{ paymentResponse.customerInfo.address.state }} {{ paymentResponse.customerInfo.address.zipCode }}</p>
            </div>

            <h2 class="mt-12 font-bold">Payment Amount</h2>
            <p>{{ paymentResponse.paymentInfo.totalAmountDue | currency }}</p>

            <h2 class="mt-12 font-bold">Payment Date</h2>
            <p>Payment due on {{ today | date }}</p>

            <h2 class="mt-12 font-bold">Payment Method</h2>
            <p>Credit Card ({{ paymentResponse.paymentMethod }})</p>
        </div>
    </div>
</div>
