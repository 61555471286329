import {NgClass, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {EdsInlineBannerComponent, EdsSvgComponent} from '@electronds/ng-components-piedmont';
import {ErrorType} from "src/app/speed-pay/models/types/ErrorType";

@Component({
    selector: 'cma-payment-error',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        EdsInlineBannerComponent,
        EdsSvgComponent
    ],
    templateUrl: './payment-error.component.html'
})
export class PaymentErrorComponent {
    @Input()
    public errorHeader: string = '';

    @Input()
    public errorMessage: string = '';

    @Input()
    public errorType: ErrorType;
}
