import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ComplexCardComponent} from "../complex-card/complex-card.component";
import {CurrencyPipe, DatePipe, NgClass, NgIf} from "@angular/common";
import {CmaModalComponent} from "../modal/modal.component";
import {CustomerDetails} from "src/app/speed-pay/models/customer-details";

@Component({
    selector: 'cma-landing',
    standalone: true,
    imports: [ComplexCardComponent, CurrencyPipe, DatePipe, NgIf, CmaModalComponent, NgClass],
    templateUrl: './landing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent {
    @Input()
    public customerDetails: CustomerDetails

    public today = Date.now();
    public isAccountDetailsModalOpen = false;

    public openDetailsModal() {
        this.isAccountDetailsModalOpen = true;
    }

    public closeDetailsModal() {
        this.isAccountDetailsModalOpen = false;
    }
}
