import {BrowserModule} from "@angular/platform-browser";
import {Component, OnInit, Renderer2} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ExternalScriptModel} from "./speed-pay/models/external-script.model";
import {AppConfigService} from "./config/app-config.service";
import {ScriptLoaderService} from "./speed-pay/services/script-loader.service";
import {CommonModule} from "@angular/common";
import {filter, from, switchMap} from "rxjs";
import {AciModel} from "./speed-pay/models/aci.model";
import {SpeedPayService} from "./speed-pay/services/speed-pay.service";
import {LandingComponent} from "./speed-pay/components/landing/landing.component";
import {CustomerDetails} from "./speed-pay/models/customer-details";
import {SessionStorageService} from "./core/services/session-storage.service";
import {PaymentConfirmationComponent} from "./speed-pay/components/payment-confirmation/payment-confirmation.component";
import {PaymentConfirmationResponse} from "./speed-pay/models/payment-confirmation-response";
import {PaymentErrorComponent} from "./speed-pay/components/payment-error/payment-error.component";

declare let aci: AciModel;

@Component({
    selector: 'cma-root',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LandingComponent,
        PaymentConfirmationComponent,
        PaymentErrorComponent
    ],
    providers: [
        BrowserModule,
        ReactiveFormsModule,
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public customerDetails: CustomerDetails = null;
    public paymentResponse: PaymentConfirmationResponse = null;
    public isPaymentSubmitted = false;
    protected isLoading = true;
    protected isError = false;
    private speedPayScript!: ExternalScriptModel;

    constructor(
        private _renderer: Renderer2,
        private _config: AppConfigService,
        private _sessionStorageService: SessionStorageService,
        private _speedPayService: SpeedPayService,
        private _scriptLoaderService: ScriptLoaderService
    ) {
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.customerDetails = this._sessionStorageService.getSessionInfoObject();
        this.postWebMessage(this.customerDetails.paymentInfo.paymentMethod + ' ==> ' + this.customerDetails.customerInfo.accountNumber);
        this.speedPayScript = {
            src: this._config
                .getSpeedPayScriptEndpoint()
                .replace('{billerId}', this._sessionStorageService.getBilledId()),
            loaded: false,
            name: 'speed-pay-init',
        };

        // load script in the head of the index.html page
        // then use the access token, and accountId from the session storage object to initialize the loaded script
        this._scriptLoaderService
            .loadScript(this.speedPayScript, this._renderer)
            .pipe(
                filter((script: ExternalScriptModel) => script.loaded),
                switchMap(() => from(this._speedPayService.initSpeedPayScript(aci)))
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.paymentResponse = {
                        confirmationNumber: '9879870998609',
                        paymentMethod: 'VISA',
                        ...this.customerDetails
                    };
                }, error: (_err) => {
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    public submitPayment(): void {
        console.log(this.isPaymentSubmitted);
    }

    public postWebMessage(message: string) {
        const listener: { postMessage: (arg0: string | ArrayBuffer) => void } = this.getWebAppListener();
        if (!!listener) {
            listener.postMessage(message);
        }
    }

    private getWebAppListener(): { postMessage: (arg0: string | ArrayBuffer) => void } | null {
        if (this._sessionStorageService.isApplePay()) {
            return window.webkit?.messageHandlers.cmaBillPayWebListener;
        } else if (this._sessionStorageService.isGooglePay()) {
            return window["cmaBillPayWebListener"];
        } else {
            return null;
        }
    }
}
