<section *ngIf="!isPaymentSubmitted"
         class="container md:container-6xl sm:container-sm">
    <div [hidden]="isLoading">
        <cma-landing
                [customerDetails]="customerDetails">
        </cma-landing>
        <div class="fixed bottom-20 left-20 right-20">
            <div class="apple-pay-button" data-aci-speedpay="apple-pay"></div>
            <div class="google-pay-button" data-aci-speedpay="google-pay"></div>
        </div>
    </div>

    <div *ngIf="isLoading"
         class="fixed inset-0 z-modal">
        <div class="flex justify-center absolute inset-0 bg-gray-darker bg-opacity-80">
            <img class="absolute top-1/2 icon-80"
                 src="assets/icons/loading.svg"
                 width="80"
                 height="80"
                 alt="loading data"/>
        </div>
    </div>
</section>

<section *ngIf="isPaymentSubmitted && !isError">
    <cma-payment-confirmation [paymentResponse]="paymentResponse"></cma-payment-confirmation>
</section>

<section *ngIf="!isLoading && isError">
    <cma-payment-error [errorType]="'SystemError'"
                       [errorHeader]="'We\'re unable to process payments at this time.'"
                       [errorMessage]="'Sorry, there was an issue processing your request. Please try again.'">

    </cma-payment-error>
</section>


